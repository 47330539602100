<template>
  <v-container class="dashboard">
   
      
    <v-row>
      <v-col sm="12" cols="12" md="12" lg="12">
        <v-card>
          <v-card-text >
           <!-- div to display the generated Instance ID token -->
          <div id="token_div" style="display: none;">
            <h4>Instance ID Token</h4>
            <p id="token" style="word-break: break-all;"></p>
           

                    <v-btn class="primary" @click="deleteToken">Delete Token</v-btn>
          </div>
          <!-- div to display the UI to allow the request for permission to
               notify the user. This is shown if the app has not yet been
               granted permission to notify. -->
          <div id="permission_div" style="display: none;">
            <h4>Needs Permission</h4>
            <p id="token"></p>
            <v-btn class="primary" @click="requestPermission">Request Permission</v-btn>
            
          </div>
          <!-- div to display messages received by this app. -->
          <div id="messages"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
  </v-container>
</template>
<style>

</style>
<script>

//import * as reportService from "../services/reportService";
import Vue from "vue";
export default {
  data() {
    return {
      messaging:null,

      // IDs of divs that display Instance ID token UI or request permission UI.
      tokenDivId : "token_div",
      permissionDivId : "permission_div",
      
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {
        
       this.messaging = Vue.prototype.$messaging;
       this.messaging.usePublicVapidKey("BKETor5zF6E2mscQf7vVb-in94ydKEQFHZYBvgDIplf6Kn_EMm3b5ta51cJ_PBAQFZrllmj3dGMp6_15ekcodKE");
        
this.messaging.requestPermission().then(()=>{
    console.log("We have permission")
    return this.messaging.getToken();
}).then((token)=>{
    console.log(token)
}).catch((err)=>{
   console.log(err)
})

        // [START refresh_token]
        // Callback fired if Instance ID token is updated.
        // this.messaging.onTokenRefresh(() => {
        //     this.messaging.getToken().then((refreshedToken) => {
        //     console.log('Token refreshed.');
        //     // Indicate that the new Instance ID token has not yet been sent to the
        //     // app server.
        //     this.setTokenSentToServer(false);
        //     // Send Instance ID token to app server.
        //     this.sendTokenToServer(refreshedToken);
        //     // [START_EXCLUDE]
        //     // Display new Instance ID token and clear UI of all previous messages.
        //     this.resetUI();
        //     // [END_EXCLUDE]
        //     }).catch((err) => {
        //     console.log('Unable to retrieve refreshed token ', err);
        //     this.showToken('Unable to retrieve refreshed token ', err);
        //     });
        // });
        // [END refresh_token]

        // [START receive_message]
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.setBackgroundMessageHandler` handler.
        // this.messaging.onMessage((payload) => {
        //     console.log('Message received. ', payload);
        //     // [START_EXCLUDE]
        //     // Update the UI to include the received message.
        //     this.appendMessage(payload);
        //     // [END_EXCLUDE]
        // });
        // // [END receive_message]


       // this.resetUI();
    },
    resetUI(){
        //this.clearMessages();
        //this.showToken('loading...');
        // [START get_token]
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        this.messaging.getToken().then((currentToken) => {
        if (currentToken) {
            this.sendTokenToServer(currentToken);
            this.updateUIForPushEnabled(currentToken);
        } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            this.updateUIForPushPermissionRequired();
            this.setTokenSentToServer(false);
        }
        }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        this.showToken('Error retrieving Instance ID token. ', err);
        this.setTokenSentToServer(false);
        });
        // [END get_token]
    },
    showToken(currentToken) {
        // Show token in console and UI.
        const tokenElement = document.querySelector('#token');
        tokenElement.textContent = currentToken;
    },
    sendTokenToServer(currentToken) {
        console.log(currentToken);
        if (!this.isTokenSentToServer()) {
        console.log('Sending token to server...');
        // TODO(developer): Send the current token to your server.
        this.setTokenSentToServer(true);
        } else {
        console.log('Token already sent to server so won\'t send it again ' +
            'unless it changes');
        }

    },
    isTokenSentToServer() {
        return window.localStorage.getItem('sentToServer') === '1';
    },
    setTokenSentToServer(sent) {
        window.localStorage.setItem('sentToServer', sent ? '1' : '0');
    },
    showHideDiv(divId, show) {
        const div = document.querySelector('#' + divId);
        if (show) {
        div.style = 'display: visible';
        } else {
        div.style = 'display: none';
        }
    },
    requestPermission() {
        console.log('Requesting permission...');
        // [START request_permission]
        Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // TODO(developer): Retrieve an Instance ID token for use with FCM.
            // [START_EXCLUDE]
            // In many cases once an app has been granted notification permission,
            // it should update its UI reflecting this.
            this.resetUI();
            // [END_EXCLUDE]
        } else {
            console.log('Unable to get permission to notify.');
        }
        });
        // [END request_permission]
    },
    deleteToken() {
    // Delete Instance ID token.
    // [START delete_token]
        this.messaging.getToken().then((currentToken) => {
        this.messaging.deleteToken(currentToken).then(() => {
            console.log('Token deleted.');
            this.setTokenSentToServer(false);
            // [START_EXCLUDE]
            // Once token is deleted update UI.
            this.resetUI();
            // [END_EXCLUDE]
        }).catch((err) => {
            console.log('Unable to delete token. ', err);
        });
        // [END delete_token]
        }).catch((err) => {
        console.log('Error retrieving Instance ID token. ', err);
        this.showToken('Error retrieving Instance ID token. ', err);
        });

    },
    appendMessage(payload) {
        const messagesElement = document.querySelector('#messages');
        const dataHeaderELement = document.createElement('h5');
        const dataElement = document.createElement('pre');
        dataElement.style = 'overflow-x:hidden;';
        dataHeaderELement.textContent = 'Received message:';
        dataElement.textContent = JSON.stringify(payload, null, 2);
        messagesElement.appendChild(dataHeaderELement);
        messagesElement.appendChild(dataElement);
    },
    clearMessages() {
        const messagesElement = document.querySelector('#messages');
        while (messagesElement.hasChildNodes()) {
                messagesElement.removeChild(messagesElement.lastChild);
        }
    },
    updateUIForPushEnabled(currentToken) {
        this.showHideDiv(this.tokenDivId, true);
        this.showHideDiv(this.permissionDivId, false);
        this.showToken(currentToken);
    },
    updateUIForPushPermissionRequired() {
        this.showHideDiv(this.tokenDivId, false);
        this.showHideDiv(this.permissionDivId, true);
    }


  }
};
</script>
<style>
.dashboard .col {
  padding: 6px;
}
</style>